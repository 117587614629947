import { useNavigate } from '@remix-run/react';
import truncate from 'lodash/truncate';
import { type KeyedMutator } from 'swr';

import { type DtoPromptTemplate } from '@lp-lib/api-service-client/public';

import { ActionSheet } from '../components/ActionSheet';
import { type Action } from '../components/ActionSheet';
import { useAwaitFullScreenConfirmCancelModal } from '../components/ConfirmCancelModalContext';
import { CopyIcon } from '../components/icons/CopyIcon';
import { DeleteIcon } from '../components/icons/DeleteIcon';
import { usePromptTemplates } from '../components/PromptTemplate';
import { PromptTemplateUtils } from '../components/PromptTemplate/utils';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { DateUtils } from '../utils/date';

type ActionSheetKeys = 'clone' | 'delete';

function PromptTemplateItem(props: {
  template: DtoPromptTemplate;
  mutate: KeyedMutator<DtoPromptTemplate[]>;
}): JSX.Element {
  const { template, mutate } = props;
  const navigate = useNavigate();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();

  const {
    state: { state },
    call: onDelete,
  } = useLiveAsyncCall(async () => {
    const response = await confirmCancel({
      kind: 'confirm-cancel',
      prompt: (
        <div className='text-white text-center text-2xl font-medium'>
          <div className='flex flex-col gap-1'>
            <div>Are you sure you want to delete this prompt template?</div>
            <div className='text-red-002'>
              All blocks used this template will be broken!!!
            </div>
          </div>
        </div>
      ),
      confirmBtnLabel: 'Delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'cancel',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;
    await apiService.promptTemplate.deleteTemplate(template.id);
    mutate();
  });

  const { call: onClone } = useLiveAsyncCall(async () => {
    await apiService.promptTemplate.cloneTemplate(template.id);
    mutate();
  });

  const actions: Action<ActionSheetKeys>[] = [
    {
      kind: 'button',
      key: 'clone',
      icon: <CopyIcon />,
      text: 'Clone Template',
      onClick: onClone,
    },
  ];

  if (PromptTemplateUtils.Editable(template)) {
    actions.push({
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete Template',
      className: 'text-red-002',
      disabled: state.isRunning,
      onClick: onDelete,
    });
  }

  return (
    <tr
      className='text-sms cursor-pointer'
      onClick={() => navigate(`./${template.id}`)}
    >
      <td className='py-1.5'>{template.name}</td>
      <td
        className={`py-1.5 ${
          PromptTemplateUtils.OwnedBySystem(template)
            ? 'text-tertiary'
            : 'text-white'
        }`}
      >
        {template.ownerType}
      </td>
      <td className='py-1.5'>
        <p>
          {template.vendor}/{template.vendorModelId}/{template.type}
        </p>
        {template.vendorObjectId && <p>({template.vendorObjectId})</p>}
      </td>
      <td className='py-1.5'>
        {truncate(template.systemPrompt, { length: 50 })}
      </td>
      <td className='py-1.5'>
        {template.functions?.length
          ? template.functions.map((f, i) => <p key={i}>{f.name}</p>)
          : 'N/A'}
      </td>
      <td className='py-1.5'>{DateUtils.FormatDatetime(template.updatedAt)}</td>
      <td className='py-1.5'>
        <ActionSheet<ActionSheetKeys> actions={actions} placement='left' />
      </td>
    </tr>
  );
}

function PromptTemplateList(): JSX.Element {
  const { data: templates, mutate } = usePromptTemplates(false);
  const navigate = useNavigate();
  return (
    <div className='flex flex-col gap-6 text-white'>
      <div className='flex items-center justify-between'>
        <p className='text-2xl font-medium'>Prompt Templates</p>
        <button
          type='button'
          className='btn-primary w-42 h-10 flex justify-center items-center'
          onClick={() => navigate('./create')}
        >
          Create Template
        </button>
      </div>
      <table className='w-full'>
        <thead>
          <tr className='text-left'>
            <th className='pb-3'>Name</th>
            <th className='pb-3'>Owned By</th>
            <th className='pb-3'>Vendor/Model/Type</th>
            <th className='pb-3'>System Prompt</th>
            <th className='pb-3'>Functions</th>
            <th className='pb-3'>Updated At</th>
            <th className='pb-3'></th>
          </tr>
        </thead>
        <tbody>
          {templates?.map((t) => (
            <PromptTemplateItem key={t.id} template={t} mutate={mutate} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function Component() {
  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />

      <PromptTemplateList />
    </AdminView>
  );
}
